import React, {useState} from 'react';
import styled from "styled-components";
import {Button, Form, Input, Space} from "antd";
import {useAppDispatch} from "../redux/hooks";
import {authAction, regAction} from "../redux/pageSlice";
import LogoSVG from "../images/LogoSVG";

const AuthWrap = styled.div`
    display: flex;
    flex-direction: column;
    background: #fafafa;
    border-radius: 8px;
    padding: 20px 40px;
    width: 100%;
    max-width: 500px;
    align-items: center;

    svg {
        width: 100%;
    }

    .ant-form {
        width: 100%;
    }
`;

const StyledAuthPage = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
    align-items: center;
    padding: 20px;

    h2 {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
    }

    h1 {
        color: white;
        margin-bottom: 20px;
    }

    .ant-btn {
        width: 100%;
    }
`;

function AuthPage() {

    const dispatch = useAppDispatch();

    const [mode, setMode] = useState<"auth" | "reg">("auth");

    const [authRef] = Form.useForm();
    const [regRef] = Form.useForm();

    return (
        <StyledAuthPage>
            <AuthWrap>
                <h1><LogoSVG/></h1>
                <h2>{mode === "reg" ? "Registration" : "Authentication"}</h2>
                {mode === "reg" ?
                    <Form form={regRef} onFinish={fields => dispatch(regAction(fields))} layout={"vertical"}>
                        <Form.Item name={"login"} rules={[{
                            required: true,
                            message: 'Please enter login!',
                        }]}>
                            <Input placeholder={"Login"}/>
                        </Form.Item>
                        <Form.Item name={"password"} rules={[{
                            required: true,
                            message: 'Please enter password!',
                        }]}>
                            <Input.Password placeholder={"Password"}/>
                        </Form.Item>
                        <Form.Item name={"confirm"} dependencies={['password']}
                                   hasFeedback
                                   rules={[
                                       {
                                           required: true,
                                           message: 'Please confirm your password!',
                                       },
                                       ({getFieldValue}) => ({
                                           validator(_, value) {
                                               if (!value || getFieldValue('password') === value) {
                                                   return Promise.resolve();
                                               }
                                               return Promise.reject(new Error('The new password that you entered do not match!'));
                                           },
                                       }),
                                   ]}>
                            <Input.Password placeholder={"Confirm Password"}/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{width: "100%"}} direction={"vertical"}>
                                <Button htmlType={"submit"}>Sign Up</Button>
                                <Button onClick={() => {
                                    regRef.resetFields();
                                    setMode("auth");
                                }}>Cancel</Button>
                            </Space>
                        </Form.Item>
                    </Form> :
                    <Form form={authRef} onFinish={fields => dispatch(authAction(fields))} layout={"vertical"}>
                        <Form.Item name={"login"}>
                            <Input placeholder={"Login"}/>
                        </Form.Item>
                        <Form.Item name={"password"}>
                            <Input.Password placeholder={"Password"}/>
                        </Form.Item>
                        <Form.Item>
                            <Space style={{width: "100%"}} direction={"vertical"}>
                                <Button htmlType={"submit"}>Sign In</Button>
                                <Button onClick={() => {
                                    authRef.resetFields();
                                    setMode("reg");
                                }}>Sign Up</Button>
                            </Space>
                        </Form.Item>
                    </Form>}
            </AuthWrap>
        </StyledAuthPage>
    );
}

export default AuthPage;