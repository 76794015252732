import React from 'react';
import styled from "styled-components";
import {Nav} from "./Nav";
import Header from "./Header";

const StyledMainLayout = styled.div`
    height: 100dvh;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
`;
const NavWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0;
    border-top: solid 1px var(--adm-color-border);
`;

function MainLayout({children}:any) {
    return (
        <StyledMainLayout>
            <Header/>
            <Content>
                {children}
            </Content>
            {/*<NavWrap>*/}
            {/*    <Nav/>*/}
            {/*</NavWrap>*/}
        </StyledMainLayout>
    );
}

export default MainLayout;