import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {editModeAction, editPercentAction, getStatusAction} from "../redux/pageSlice";
import {Button, InputNumber, Radio, Space} from "antd";
import {Modal} from "antd-mobile";

const BlockWrap = styled.div`
    display: flex;
    padding-bottom: 20px;
    width: 100%;
`;

const TopBlock = styled.div`
    display: flex;
    flex-direction: column;
    background: #fafafa;
    width: 100%;
    padding: 20px;
    border-radius: 12px;

    h2 {
        font-weight: 600;
        margin-bottom: 20px;
    }

    .ant-input-number {
        width: 70px;
    }

    .ant-input-number input {
        font-size: 14px;
        height: auto;
        min-height: auto;
        line-height: 14px;
        //text-align: right;
    }

    p {
        margin: 0;
    }

    .ant-radio-group {
        flex-direction: column;
        display: flex;

        label {
            height: 30px;
            align-items: center;
        }
    }

    h1 {
        span {
            font-weight: 600;
        }
    }
`;

const StyledSettingsPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 20px;

    .adm-button {
        &:not(.adm-button-fill-none) {
            background: #26a59a !important;
            border-color: #26a59a;
        }

        &.adm-button-fill-none {
            color: #26a59a;
        }
    }

    .ant-radio-checked .ant-radio-inner {
        border-color: #26a59a;
        background: #26a59a;
    }
`;

function SettingsPage() {
    const {name} = useParams();
    const dispatch = useAppDispatch();
    const status = useAppSelector((state) => state.page.status);
    // const isMounted = useIsMounted()

    useEffect(() => {
        dispatch(getStatusAction());
    }, []);


    const statusItem = useMemo(() => {
        return status.filter(el => el.Name === name)[0]
    }, [status, name]);

    const [inputValue, setInputValue] = useState<number>(0);
    // const [debouncedValue] = useDebounce(inputValue, 500);
    // const [isUpdatingFromServer, setIsUpdatingFromServer] = useState(false);


    // useEffect(() => {
    //     if (statusItem) {
    //         const newValue = (statusItem.Percent || 0) * 100;
    //         if (inputValue !== newValue) {
    //             setIsUpdatingFromServer(true);
    //             setInputValue(newValue);
    //         }
    //     }
    // }, [statusItem?.Percent]);

    // useEffect(() => {
    //     // Проверяем, инициировано ли обновление значением с сервера
    //     if (debouncedValue !== null && !isUpdatingFromServer && debouncedValue !== (statusItem?.Percent || 0) * 100) {
    //         // Отправляем запрос на обновление
    //         // updateStatus(debouncedValue / 100); // Предполагается, что updateStatus принимает значение от 0 до 1
    //         console.log("debouncedValue", debouncedValue / 100);
    //         dispatch(editPercentAction({name: statusItem?.Name, percent: debouncedValue / 100}))
    //     }
    //     // Сбрасываем флаг после использования
    //     setIsUpdatingFromServer(false);
    // }, [debouncedValue, statusItem?.Percent]);

    const [selectedMode, setSelectedMode] = useState<0 | 1 | 2 | 3 | 4>(1);

    useEffect(() => {
        setSelectedMode(statusItem?.Mode as any)
    }, [statusItem?.Mode]);

    useEffect(() => {
        setInputValue((statusItem?.Percent || 0) * 100)
    }, [statusItem]);

    // useEffect(() => {
    //     setInputValue((statusItem?.Percent || 0) * 100)
    // }, [statusItem?.Percent]);

    // useEffect(() => {
    //     if ((debouncedValue / 100) !== statusItem?.Percent)
    //         console.log("test:", (debouncedValue / 100));
    //     // dispatch(editPercentAction({name: statusItem?.Name, percent: debouncedValue}))
    // }, [isMounted, statusItem, debouncedValue]);
    //
    // useEffect(() => {
    //     if (isMounted())
    //         console.log("debouncedValue:", (debouncedValue / 100));
    //     // dispatch(editPercentAction({name: statusItem?.Name, percent: debouncedValue}))
    // }, [debouncedValue]);

    return (
        <StyledSettingsPage>
            <TopBlock>
                <h1><span>Settings</span> - {statusItem?.Name}</h1>
                <Space direction={"vertical"}>
                    {/*<span><strong>Select Pool:</strong></span>*/}
                    <strong>Mode:</strong>
                    <Space style={{alignItems: "flex-end", width: "100%", justifyContent: "space-between"}}>
                        <Space direction={"vertical"}>
                            <Radio.Group value={selectedMode}
                                         onChange={({target: {value}}) => {
                                             setSelectedMode(value)
                                             // dispatch(editModeAction({
                                             //     name: statusItem?.Name,
                                             //     mode: value
                                             // }))
                                         }}>
                                <Radio value={0}>Admin</Radio>
                                <Radio value={1}>Off</Radio>
                                <Radio value={2}>DevFee</Radio>
                                {/*<Radio value={3}>MyFee <span*/}
                                {/*    style={{display: ![3].includes(selectedMode) ? "none" : "inline-block"}}><InputNumber*/}
                                {/*    value={inputValue}*/}
                                {/*    onChange={(value: any) => setInputValue(value || 0)}*/}
                                {/*    min={0} max={100}/> %</span></Radio>*/}
                                <Radio value={4}>DevFee&MyFee </Radio>
                            </Radio.Group>
                            <span
                                style={{display: "inline-block"}}>
                                <Space>
                                    <strong>MyFee:</strong>
                            <InputNumber
                                value={inputValue}
                                onChange={(value: any) => setInputValue(value || 0)}
                                min={0} max={100}/>
                                </Space> %
                                </span>
                        </Space>
                        <Button onClick={() => {
                            Modal.confirm({
                                title: 'Confirm action',
                                content: 'Are you sure you want to save your settings?',
                                getContainer: () => document.querySelector(`.${StyledSettingsPage.styledComponentId}`)!,
                                confirmText: "Save",
                                cancelText: "Cancel",
                                onConfirm: () => dispatch(editModeAction({
                                    name: statusItem?.Name,
                                    mode: selectedMode
                                })).unwrap().then(() => {
                                    dispatch(editPercentAction({
                                        name: statusItem?.Name,
                                        percent: inputValue / 100
                                    })).unwrap().finally(() => {
                                        dispatch(getStatusAction())
                                    }).catch(({response}) => {
                                    })

                                    // if ([3, 4].includes(selectedMode)) {
                                    //     dispatch(editPercentAction({
                                    //         name: statusItem?.Name,
                                    //         percent: inputValue / 100
                                    //     })).unwrap().finally(() => {
                                    //         dispatch(getStatusAction())
                                    //     }).catch(({response}) => {
                                    //     })
                                    // } else {
                                    //     dispatch(getStatusAction())
                                    // }
                                }).catch(({response}) => {
                                })
                            });
                        }}>Apply</Button>
                    </Space>
                </Space>
            </TopBlock>
            <TopBlock>
                <h2>Описание режимов</h2>
                <p><strong>Off</strong></p>
                <p>сервис в работу не вмешивается.</p>
                <br/>
                <p><strong>DevFee</strong></p>
                <p>сервис позволяет вернуть на Ваш пул все DevFee которые уходят разработчикам кастомных
                    прошивок.</p>
                <p>Стандартная прошивка = 0%</p>
                <p>Кастомная прошивка = 2-5%</p>
                <br/>
                <p><strong>DevFee&MyFee + поле процента</strong></p>
                <p>данный режим позволяет вернуть на Ваш пул все DevFee и дополнительный процент для всех
                    мощностей.</p>
                <p>Стандартная прошивка = 0% + указанный %</p>
                <p>Кастомная прошивка = 2-5% + указанный %</p>
                <br/>
                <p><strong>Admin</strong></p>
                <p>сервис работает согласно тонкой ручной настройки администратора сервиса. позволяет указывать
                    разные
                    процент для разных воркеров/прошивок/пулов.</p>
            </TopBlock>
            <BlockWrap>
                <TopBlock>
                    <h2>Description of modes</h2>
                    <p><strong>Off</strong></p>
                    <p>the service does not interfere with the work.</p>
                    <br/>
                    <p><strong>DevFee</strong></p>
                    <p>the service allows you to return all DevFees that go to custom firmware developers back to your
                        pool.</p>
                    <p>Standard firmware = 0%</p>
                    <p>Custom firmware = 2-5%</p>
                    <br/>
                    <p><strong>DevFee&MyFee + percentage field</strong></p>
                    <p>this mode allows you to return all DevFees and an additional percentage for all power back to
                        your pool.</p>
                    <p>Standard firmware = 0% + specified %</p>
                    <p>Custom firmware = 2-5% + specified %</p>
                    <br/>
                    <p><strong>Admin</strong></p>
                    <p>the service operates according to fine manual settings by the service administrator, allowing
                        different
                        percentages for different workers/firmware/pools.</p>
                </TopBlock>
            </BlockWrap>
        </StyledSettingsPage>
    );
}

export default SettingsPage;