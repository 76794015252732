import React, {useEffect} from 'react';
import './App.css';
import History from "./utils/history";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {getUserAction} from "./redux/pageSlice";
import styled from "styled-components";
import AuthPage from "./pages/AuthPage";
import MainLayout from "./components/MainLayout";
import PoolsPage from "./pages/PoolsPage";
import DashboardPage from "./pages/DashboardPage";
import NewDashboardPage from "./pages/NewDashboardPage";
import SettingsPage from "./pages/SettingsPage";

const StyledApp = styled.div`
    display: flex;
    flex-direction: column;
    background: #2ba79d;
`;

function App() {
    const user = useAppSelector((state) => state.page.user);
    const loading = useAppSelector((state) => state.page.loading);

    const location = useLocation();
    History.navigate = useNavigate();
    History.location = location;

    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getUserAction())
    }, []);

    return (
        <StyledApp>
            <Routes>
                {loading ? <Route path={"*"} element={
                    <MainLayout>
                        Загрузка...
                    </MainLayout>
                }></Route> : user?.Login ? <>
                    <Route path={"/"} element={<MainLayout>
                        <NewDashboardPage/>
                    </MainLayout>}/>
                    <Route path={"/settings/:name"} element={<MainLayout>
                        <SettingsPage/>
                    </MainLayout>}/>
                    <Route path={"/pools"} element={<MainLayout>
                        <PoolsPage/>
                    </MainLayout>}/>
                    <Route path={"*"} element={<MainLayout>

                    </MainLayout>}/>
                    {/*<Route path={"/project/new"} element={<DashboardLayout>*/}
                    {/*  <NewProjectPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/"} element={<DashboardLayout>*/}
                    {/*  <CurrentProjectsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/tariffs"} element={<DashboardLayout>*/}
                    {/*  <TariffsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/integrations"} element={<DashboardLayout>*/}
                    {/*  <IntegrationsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/database"} element={<DashboardLayout>*/}
                    {/*  <DataBasePage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/account"} element={<DashboardLayout>*/}
                    {/*  <AccountPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/exit"} element={<DashboardLayout>*/}
                    {/*  <ExitPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                    {/*<Route path={"/projects"} element={<DashboardLayout>*/}
                    {/*  <OurProjectsPage/>*/}
                    {/*</DashboardLayout>}/>*/}
                </> : <>
                    <Route path={"*"} element={<AuthPage/>}/>
                </>}
            </Routes>
        </StyledApp>
    );
}

export default App;
